import React, { useEffect, useState } from 'react';
import {
    UserOutlined,
    ContainerOutlined,
    DesktopOutlined,
    DatabaseOutlined,
    PieChartOutlined,
    ReadOutlined,
    FileOutlined,
    ClockCircleOutlined,
    HighlightOutlined,
    ProfileOutlined,
} from '@ant-design/icons';
import { Image, Menu, Space } from 'antd';
import Sider from 'antd/es/layout/Sider';
import Title from 'antd/es/typography/Title';
import { useSelector } from 'react-redux';
import { ThemeEnum } from 'Redux/enums';
import { Link, useLocation } from 'react-router-dom';
import { IoFastFoodOutline } from "react-icons/io5";
import { BsDistributeHorizontal } from "react-icons/bs";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { RiCoupon2Line } from "react-icons/ri";
import { RiMovie2Line } from "react-icons/ri";
import { SiGoogleclassroom } from "react-icons/si";
import { skvLogo, skvWhiteLogo } from 'Assets';

function getItem(label, key, icon, linkTo, children, type, allowedpermissions = []) {

    if (!key) {
        key = linkTo || label.replace(" ", "").toLowerCase();
    }

    const itemContent = linkTo ? (
        <Link to={linkTo}>{label}</Link>
    ) : (
        label
    );

    return {
        key,
        icon,
        children,
        label: itemContent,
        type,
        allowedpermissions,
    };
}

function checkAllowedPermissions(userPermissions) {

    let new_items = [];

    for (let item of all_items) {
        if (!item.children) {
            // for no children / no drop down
            let allPermissionsPresentIniItem = item.allowedpermissions.filter(i => userPermissions?.includes(i)).length === item.allowedpermissions.length;
            if (allPermissionsPresentIniItem) {
                new_items.push(item);
            }
        } else {
            // create an array of all the permissions
            let allPermissionsInsideDropdown = []
            item.children.forEach(ite => {
                ite.allowedpermissions.forEach(p => {
                    allPermissionsInsideDropdown.push(p);
                })
            });

            // determine if this dropdown should be shown
            let showDropdown = false;
            for (let alp of allPermissionsInsideDropdown) {
                if (userPermissions?.includes(alp)) {
                    showDropdown = true;
                    break;
                }
            }

            // find the children that are to be shown
            let dropdownItem = { ...item };
            if (showDropdown) {
                let newChildren = [];
                for (let child of dropdownItem.children) {
                    let allPermissionsPresentIniItem = child.allowedpermissions.filter(i => userPermissions?.includes(i)).length === child.allowedpermissions.length;
                    if (allPermissionsPresentIniItem) {
                        newChildren.push(child);
                    }
                }
                if (newChildren.length > 0) {
                    dropdownItem.children = newChildren;
                    new_items.push(dropdownItem);
                }
            }
        }
    }

    return new_items;

}

const all_items = [
    getItem('Dashboard', "dashboard", <PieChartOutlined />, "/dashboard", null, null, ["dashboard:view"]),
    getItem('Blogs & Promotions', "blogs-promotions", <ReadOutlined />, null, [
        getItem('Blogs', null, null, "/blog-promotion/blogs", null, null, ["blogs:view"]),
        getItem('Promotions', null, null, "/blog-promotion/promotions", null, null, ["promotions:view"]),
    ]),
    getItem('Review', "review", <HighlightOutlined />, null, [
        getItem('Reviews', null, null, "/review/reviews", null, null, ["reviews:view"]),
    ]),
    getItem('Subscriptions & Contact Us', "subscriptions-contactus", <ProfileOutlined />, null, [
        getItem('Subscriptions', null, null, "/subscription-contactus/subscriptions", null, null, ["subscriptions:view"]),
        getItem('Contact Us', null, null, "/subscription-contactus/contactus", null, null, ["contactus:view"]),
    ]),
    getItem('Document', "document", <FileOutlined />, null, [
        getItem('Documents', null, null, "/document/documents", null, null, ["documents:view"]),
    ]),
    getItem('Master', "master", <DatabaseOutlined />, null, [
        getItem('Countries', null, null, "/master/countries", null, null, ["countries:view"]),
        getItem('Languages', null, null, "/master/languages", null, null, ["languages:view"]),
        getItem('Document Types', null, null, "/master/document-types", null, null, ["documenttypes:view"]),
        getItem('Services', null, null, "/master/services", null, null, ["services:view"]),
        getItem('Plans', null, null, "/master/plans", null, null, ["plans:view"]),
    ]),
    getItem('Session', "session", <ClockCircleOutlined />, null, [
        getItem('Sessions', null, null, "/session/sessions", null, null, ["sessions:view"]),
    ]),
    // getItem('Order', null, <MdOutlineShoppingCartCheckout />, null, [
    //     getItem('Orders', null, null, "/order/orders", null, null, ["order:view"]),
    //     getItem('Payments', null, null, "/order/payments", null, null, ["order:view"]),
    // ]),
    getItem('Platform Users', "platform-users", <UserOutlined />, null, [
        getItem('Users', null, null, "/platform-users/users", null, null, ["users:view"]),
        getItem('Permissions', null, null, "/platform-users/permissions", null, null, ["permissions:view"]),
        getItem('Roles', null, null, "/platform-users/roles", null, null, ["roles:view"]),
    ]),
];


const SideBar = ({ trigger, collapsible, collapsed }) => {
    const location = useLocation();
    const { pathname } = location;
    const currentTheme = useSelector(store => store.utilities.theme);

    const [defaultOpenKeyState, setDefaultOpenKey] = useState([]);
    const [defaultSelectedKeyState, setDefaultSelectedKey] = useState([]);

    const user = useSelector(state => state.auth.user)
    // console.log({ all_items })
    const filtered_items = checkAllowedPermissions(user.permissions);

    useEffect(() => {
        let defaultOpenKey = [];
        if (pathname?.includes("master")) {
            defaultOpenKey = ["master"];
        } else if (pathname?.includes("clients")) {
            defaultOpenKey = ["clients"];
        } else if (pathname?.includes("platform")) {
            defaultOpenKey = ["platform-users"];
        } else if (pathname?.includes("theatres")) {
            defaultOpenKey = ["theatres"];
        } else if (pathname?.includes("shows")) {
            defaultOpenKey = ["shows"];
        } else if (pathname?.includes("coupon")) {
            defaultOpenKey = ["coupons"];
        } else if (pathname?.includes("food")) {
            defaultOpenKey = ["food"];
        } else if (pathname?.includes("order")) {
            defaultOpenKey = ["order"];
        } else {
            defaultOpenKey = ["dashboard"]
        }
        setDefaultOpenKey(defaultOpenKey);
        // console.log({ defaultOpenKey });

        if (pathname?.includes("theatre")) {
            // console.log({ pathname })
            if (pathname?.includes("users")) {
                setDefaultSelectedKey("/theatres/users");
            } else if (pathname?.includes("channels")) {
                setDefaultSelectedKey("/theatres/channels");
            } else if (pathname?.includes("halls")) {
                setDefaultSelectedKey("/theatres/halls");
            } else if (pathname?.includes("seat-types")) {
                setDefaultSelectedKey("/theatres/seat-types");
            } else if (pathname?.includes("tax")) {
                setDefaultSelectedKey("/theatres/tax");
            } else {
                setDefaultSelectedKey("/theatres");
            }
        } else {
            setDefaultSelectedKey(pathname);
        }
    }, [pathname]);


    return (
        <Sider theme={currentTheme} trigger={trigger} collapsible={collapsible} collapsed={collapsed} style={{ maxHeight: "100%", overflow: "auto" }}>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
                <Title level={collapsed ? 5 : 3} type='secondary' style={{ color: currentTheme === ThemeEnum.DARK ? "#fff" : "#000" }}>
                    {currentTheme === ThemeEnum.DARK ?
                        <Image src={skvWhiteLogo} preview={false} style={{ maxWidth: 100 }} />
                        :
                        <Image src={skvLogo} preview={false} style={{ maxWidth: 100 }} />
                    }
                </Title>
            </Space>
            {
                Array.isArray(defaultOpenKeyState) && defaultOpenKeyState.length > 0 ?
                    <Menu
                        defaultSelectedKeys={[defaultSelectedKeyState]}
                        selectedKeys={defaultSelectedKeyState}
                        defaultOpenKeys={defaultOpenKeyState}
                        mode="inline"
                        theme={currentTheme}
                        items={filtered_items}
                    /> : <></>
            }
        </Sider>
    );
};
export default SideBar;