import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Upload, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord, fetchAllServices } from 'Redux/MasterReducer/crudSlices';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons"
import TextArea from 'antd/es/input/TextArea';

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [services, setServices] = useState([]);

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);
  const [serviceImageUrl, setServicesImageUrl] = useState('')
  const [selectedService, setSelectedService] = useState(null)

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
    return form.resetFields();
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    form.setFieldsValue(currentRecordData);
    setSelectedService(currentRecordData.parent_service)
    setServicesImageUrl(currentRecordData.service_image);
  }, [fetchRecordStatus, currentRecordData]);

  const fetchServices = async () => {
    const response = await fetchAllServices();
    if (response) {
      setServices(response.results)
    }
  }

  useEffect(() => {
    fetchServices();
  }, [])

  useEffect(() => {
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    } else {
      // clear an edit form after closing it
      form.resetFields();
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const addNew = (val) => {
    dispatch(addARecord({ apiUrl: apiUrl, data: val, headers: { "Content-Type": "multipart/form-data" } }));
  }

  const edit = (val) => {
    dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val, headers: { "Content-Type": "multipart/form-data" } }));
  }

  const onFinish = (val) => {
    if (val['service_image']['fileList']) {
      let currentAuthorImage = val['service_image']['fileList'][0]['originFileObj']
      val['service_image'] = currentAuthorImage;
    }
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
    } else {
      console.log("No suitable mode found");
    }
  }

  const serviceImageBeforeUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setServicesImageUrl(reader.result);
    };
    return false;
  };

  return (
    <>
      {contextHolder}
      <Form layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item name="parent_service" label="Parent Service">
          <Select
            placeholder="Select a Service"
            allowClear
            value={selectedService}
            onChange={(e) => setSelectedService(e)}
          >
            {services.map((service) => {
              return <option key={service.id} value={service.id}>{service.title}</option>
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: 'Please enter title',
            },
          ]}
        >
          <Input placeholder="Please enter title" />
        </Form.Item>
        <Form.Item
          name="slug"
          label="Slug"
        // rules={[
        //   {
        //     required: true,
        //     message: 'Please enter slug',
        //   },
        // ]}
        >
          <Input placeholder="Please enter slug" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea rows={4} placeholder="Enter Description" />
        </Form.Item>
        <Form.Item
          name="priority"
          label="Priority"
          rules={[
            {
              required: true,
              message: 'Please enter Priority',
            },
          ]}
        >
          <Input type="number" placeholder="Please enter Priority" />
        </Form.Item>
        <Form.Item name="service_image" label="Service Image">
          <Upload
            beforeUpload={serviceImageBeforeUpload}
            showUploadList={false}
          >
            {serviceImageUrl ? (
              <img src={serviceImageUrl} alt="service image" style={{ maxWidth: '100%', maxHeight: '200px' }} />
            ) : (
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            )}
          </Upload>
        </Form.Item>
        {
          currentFormMode === FormModesEnum.ADD ? <Form.Item>
            {
              addRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Submitting
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
            }
          </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
            {
              editRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Updating
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Update
                </Button>
            }
          </Form.Item> : <></>
        }
      </Form >
    </>
  );
};

export default AddEditForm;