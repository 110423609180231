import pluralize from "pluralize";

const getHeaderTitle = (headerTitle) => {
    // console.log({headerTitle})
    // if (headerTitle.includes("ies")){
    //     let newHT = headerTitle.replace("ies","y");
    //     return newHT;
    // } else {
    //     return headerTitle?.slice(0, -1);
    // }
    return pluralize.singular(headerTitle);
}

export default getHeaderTitle;