import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as actions from "Redux/MasterReducer/crudSlices";
import FormElements from "Components/Forms/ContactUs/FormElements";
import { Button, Form, Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons"

const ContactUsServicePage = () => {

    const { contactUsId } = useParams();
    const [form] = Form.useForm();
    const [isUpdating, setIsUpdating] = useState(false);
    const [contactUsData, setContactUsData] = useState(null);

    const fetchContactUs = async () => {
        const response = await actions.getContactUsInfo(contactUsId);
        if (response) {
            setContactUsData(response);
        }
    };

    useEffect(() => {
        if (contactUsData) {
            form.setFieldsValue(contactUsData);
        }
    }, [contactUsData]);

    useEffect(() => {
        if (contactUsId) {
            fetchContactUs();
        }
    }, [contactUsId])

    return (
        <div>
            <h3>Services requested by</h3>
            {contactUsData &&
                <Form
                    layout="vertical"
                    requiredMark={true}
                    autoComplete="off"
                    form={form}
                    style={{ width: '50%' }}
                >
                    <FormElements />
                    <Form.Item name="contacted" label="Contacted by SKV?">
                        <Switch />
                    </Form.Item>
                    <Form.Item label="Services Requested:">
                        {contactUsData.services.map((service, index) => {
                            return (
                                <div key={service.id}>
                                    {index + 1}. {service.name}
                                </div>
                            )
                        })}
                    </Form.Item>
                    <Form.Item>
                        {isUpdating ?
                            <Button type="primary" htmlType="submit" disabled>
                                <LoadingOutlined />
                                Updating
                            </Button> :
                            <Button type="primary" htmlType="submit" >
                                Update
                            </Button>
                        }
                    </Form.Item>
                </Form>
            }
        </div >
    )
}

export default ContactUsServicePage;