import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import PromotionAddEditForm from "Components/Forms/Promotion/PromotionAddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { Image } from "antd";
import { useSelector } from "react-redux";

const apiUrl = "/promotion/rest/promotions";
const headerTitle = "Promotions";
const editPermission = "promotions:edit";
const deletePermission = "promotions:delete";
const addPermission = "promotions:add";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Promotion Image',
        dataIndex: 'image',
        key: 'image',
        render: (text) => <Image src={text} />,
    },
    {
        title: 'Visible',
        dataIndex: 'is_visible',
        key: 'is_visible',
        render: (isVisible) => (isVisible ? 'Yes' : 'No'),
    },
    {
        title: 'Created At Date',
        dataIndex: 'created_at',
        key: 'created_at',
    },
    ...getCommonColumns({ apiUrl, deletePermission, editPermission, created: true, updated: true, action: true })
]

const PromotionsPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={PromotionAddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default PromotionsPage;