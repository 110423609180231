import CustomTable from "Components/CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { RenderCreated, RenderUpdated, RenderActionButtons, getCommonColumns } from "Components/CustomTable/Commons";
import PlanAddEditForm from "Components/Forms/Master/Plans/PlanAddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";

const apiUrl = "/plan/rest/plans";
const headerTitle = "Plans";
const editPermission = "plans:edit";
const deletePermission = "plans:delete";
const addPermission = "plans:add";

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Service',
        dataIndex: 'service_title',
        key: 'service_title',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        // render: (text) => <a>{text}</a>,
    },
    // {
    //     title: 'Price',
    //     dataIndex: 'price',
    //     key: 'price',
    // },
    {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Is Published?',
        dataIndex: 'is_published',
        key: 'is_published',
        render: (text, row, index) => {
            return row.is_published ? "Yes" : "No"
        },
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
];

const PlansPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={PlanAddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default PlansPage;