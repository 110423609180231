import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Drawer, Flex, Spin, Table, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { changeHeaderTitle } from 'Redux/UtilitiesReducer/utilitySlice';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { useSearchParams } from 'react-router-dom';
import { fetchAllRecords, incrementPageNumber, resetAddRecordStatus, resetDeleteRecordStatus, resetEditRecordStatus, resetFetchRequestStatus, resetFormModeToNone, resetMasterSlice, setFormModeAsAdd, setFormModeAsEdit, setFormModeAsView } from 'Redux/MasterReducer/crudSlices';
import CustomPagination from './CustomPagination';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import searchParamsEnum from 'Utils/searchParamsEnum';
import Search from './Search';
import { PlusCircleOutlined } from "@ant-design/icons";
import Filters from './Filters';
import getHeaderTitle from 'Utils/getHeaderTitle';
import AccessControl from 'Router/Access/AccessControl';
import DrawerComponent from './DrawerComponent';
import responseCodeMessages from 'Utils/responseCodeMessages.enum';

const CustomTable = ({
    columns = [],
    dataFetchingFunction,
    apiUrl,
    extraApiParams,
    filters,
    headerTitle,
    useSelectorKeys,
    MyFormComponent,
    ViewComponent,
    OtherFormComponent,
    formCompononentStyle = {},
    nonViewFormCompononentStyle = {},
    addPermissionNames = []
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [extraApiParamsState, setExtraApiParamsState] = useState(extraApiParams);

    const [open, setOpen] = useState(false);
    const [notificationApi, contextHolder] = notification.useNotification();

    const fetchAllTimerForDebouncing = useRef(null);

    const pageNumber = useSelector(store => store.master?.currentPageNumber);
    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);

    const user = useSelector(store => store.auth.user);

    useEffect(() => {
        // console.log({ currentEditViewFormId, currentFormMode });
        if (currentFormMode !== FormModesEnum.NONE) {
            changeFormMode(currentFormMode, currentEditViewFormId);
        }
    }, [currentFormMode, currentEditViewFormId]);

    useEffect(() => {
        dispatch(resetMasterSlice());
    }, []);

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    const changeFormMode = (newMode, id) => {
        if (newMode !== FormModesEnum.ADD && newMode !== FormModesEnum.NONE) {
            if (!id) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            }
            if (Object.keys(FormModesEnum).includes(newMode)) {
                setOpen(true);
                if (newMode === FormModesEnum.EDIT) {
                    dispatch(setFormModeAsEdit({ id: id }));
                } else if (newMode === FormModesEnum.VIEW) {
                    dispatch(setFormModeAsView({ id: id }));
                }
            } else {
                openNotification("Something went wrong", "Please contact developers. Provided mode does not exist.", "error");
                return;
            }
        } else if (newMode === FormModesEnum.ADD) {
            setOpen(true);
            dispatch(setFormModeAsAdd());
        }
    }

    const resetFormMode = () => {
        setOpen(false);
        dispatch(resetFormModeToNone());
    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeHeaderTitle(headerTitle));

        return () => dispatch(changeHeaderTitle("SKV Business"))
    }, [dispatch]);

    const data = useSelector(store => store.master.data);
    // console.log(data);
    const dataTotalCount = useSelector(store => store.master.count);

    const fetchRequestStatus = useSelector(store => store.master.fetchRequestStatus);
    const fetchRequestErrorMsg = useSelector(store => store.master.fetchRequestErrorMsg);
    const deleteRecordStatus = useSelector(store => store.master.deleteRecordStatus);
    const deleteRecordErrorMsg = useSelector(store => store.master.deleteRecordErrorMsg);
    const addRecordStatus = useSelector(store => store.master.addRecordStatus);
    const addRecordErrorMsg = useSelector(store => store.master.addRecordErrorMsg);
    const editRecordStatus = useSelector(store => store.master.editRecordStatus);
    const editRecordErrorMsg = useSelector(store => store.master.editRecordErrorMsg);

    useEffect(() => {
        // console.log({ searchParams, extraApiParams, apiUrl })
        if (apiUrl) {
            clearTimeout(fetchAllTimerForDebouncing.current);
            fetchAllTimerForDebouncing.current = setTimeout(() => {
                dispatch(fetchAllRecords({ apiUrl, extraApiParams: extraApiParamsState, searchParams, pageNumber: pageNumber }));
            }, 300);
        }
    }, [dispatch, searchParams, apiUrl, extraApiParamsState, pageNumber]);

    useEffect(() => {

        const handleScroll = (e) => {
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            // console.log(bottom)
            if (bottom) {
                dispatch(incrementPageNumber());
            }
        };

        const antTableBody = document.querySelector('.ant-table-body');
        if (antTableBody) {
            antTableBody.addEventListener('scroll', handleScroll);
        }

        return () => antTableBody.removeEventListener('scroll', handleScroll);
    }, []);



    useEffect(() => {
        if (fetchRequestStatus && fetchRequestStatus !== asyncStatuses.LOADING) {
            setTimeout(() => dispatch(resetFetchRequestStatus()), 300);
            if (fetchRequestStatus === asyncStatuses.FAILED) {
                openNotification("Error occurred while fetching records.", fetchRequestErrorMsg, "error")
            }
        }
        if (deleteRecordStatus && deleteRecordStatus === asyncStatuses.SUCCESS) {
            setTimeout(() => {
                dispatch(resetDeleteRecordStatus());
                dispatch(fetchAllRecords({ apiUrl, extraApiParams: extraApiParamsState, searchParams, pageNumber: pageNumber }));
                openNotification(`Successfully deleted the ${getHeaderTitle(headerTitle)}`, null, "success");
            }, 300);
        }
        if (deleteRecordStatus && deleteRecordStatus === asyncStatuses.FAILED) {
            setTimeout(() => {
                let errorMsg = deleteRecordErrorMsg;
                dispatch(resetDeleteRecordStatus());
                openNotification(`Error while deleting the ${getHeaderTitle(headerTitle)}`, errorMsg, "error");
            }, 300);
        }
        if (addRecordStatus && addRecordStatus === asyncStatuses.SUCCESS) {
            setTimeout(() => {
                dispatch(resetAddRecordStatus());
                resetFormMode();
                dispatch(fetchAllRecords({ apiUrl, extraApiParams: extraApiParamsState, searchParams, pageNumber: pageNumber }));
                openNotification(`Successfully added new ${getHeaderTitle(headerTitle)}`, null, "success");
                setTimeout(() => dispatch(resetAddRecordStatus()), 300);
            }, 300);
        }
        if (addRecordStatus && addRecordStatus === asyncStatuses.FAILED) {
            setTimeout(() => {
                let errorMsg = addRecordErrorMsg;
                if (typeof errorMsg === "object") {
                    errorMsg = addRecordErrorMsg?.message || null
                }
                openNotification(`Error while adding the ${getHeaderTitle(headerTitle)}`, errorMsg, "error");
                dispatch(resetAddRecordStatus());
            }, 300);
        }
        if (editRecordStatus && editRecordStatus === asyncStatuses.SUCCESS) {
            dispatch(resetEditRecordStatus());
            resetFormMode();
            dispatch(fetchAllRecords({ apiUrl, extraApiParams: extraApiParamsState, searchParams, pageNumber: pageNumber }));
            openNotification(`Successfully updated ${getHeaderTitle(headerTitle)}`, null, "success");
            setTimeout(() => dispatch(resetEditRecordStatus()), 300);
        }
        if (editRecordStatus && editRecordStatus === asyncStatuses.FAILED) {
            setTimeout(() => {
                let errorMsg = deleteRecordErrorMsg;
                dispatch(resetEditRecordStatus());
                openNotification(`Error while editing the ${getHeaderTitle(headerTitle)}`, errorMsg, "error");
            }, 300);
        }
    }, [fetchRequestStatus, deleteRecordStatus, dispatch, addRecordStatus, editRecordStatus, apiUrl, searchParams, headerTitle, openNotification]);

    return (<>
        {contextHolder}
        <Flex vertical align='start' justify='space-between' style={{ position: "relative" }}>
            <Flex justify='space-between' align='center' style={{ width: "100%", padding: 10 }}>
                <Title style={{ margin: 0 }} level={5}>
                    Showing {(Array.isArray(data) && data.length) || 0} of {dataTotalCount || 0}
                </Title>
                <Flex gap={5}>
                    <Filters
                        filters={filters}
                        apiUrl={apiUrl}
                        extraApiParamsState={extraApiParamsState}
                        setExtraApiParamsState={setExtraApiParamsState}
                    />
                    <Search />
                    <AccessControl
                        userPermissions={user.permissions}
                        allowedpermissions={addPermissionNames}
                        renderNoAccess={() => (
                            <></>
                        )}
                    >
                        <Button type="primary" onClick={() => changeFormMode(FormModesEnum.ADD, null)} icon={<PlusCircleOutlined />}>
                            New
                        </Button>
                    </AccessControl>
                </Flex>
            </Flex>
            {/* <div style={{ height: "68vh", overflow: "scroll", marginTop: 20, width: "100%" }} onScroll={handleScroll}> */}
            <Table
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            // console.log({ record, rowIndex, event });
                            if (event.target.localName === "td") {
                                dispatch(setFormModeAsView({ id: record.id || record.uuid }));
                            }
                        }, // click row
                        // onDoubleClick: (event) => { }, // double click row
                        // onContextMenu: (event) => { }, // right button click row
                        // onMouseEnter: (event) => { }, // mouse enter row
                        // onMouseLeave: (event) => { }, // mouse leave row
                    };
                }}

                columns={columns}
                dataSource={data}
                size='small'
                scrollToFirstRowOnChange
                stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                loading={fetchRequestStatus === asyncStatuses.LOADING && data.length === 0}
                scroll={{
                    y: "65vh",
                    x: "100%"
                }}
                pagination={false}
            />
            {/* </div> */}
            <Flex vertical justify='center' align='center' flex={1} style={{ width: "100%", height: '6vh', position: 'absolute', bottom: 20 }}>
                {/* <CustomPagination /> */}
                {fetchRequestStatus === asyncStatuses.LOADING && data.length > 0 && <>
                    <Spin size="small" />
                    <Text style={{ color: "#4096FF" }}  >Loading...</Text>
                </>}
            </Flex>
        </Flex>
        <DrawerComponent
            formCompononentStyle={formCompononentStyle}
            nonViewFormCompononentStyle={nonViewFormCompononentStyle}
            resetFormMode={resetFormMode}
            open={open}
            headerTitle={headerTitle}
            MyFormComponent={MyFormComponent}
            apiUrl={apiUrl}
            ViewComponent={ViewComponent}
            changeFormMode={changeFormMode}
        />
    </>)
}

export default CustomTable;