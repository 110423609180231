import { RenderCreated, getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import SessionAddEditForm from "Components/Forms/Session/SessionAddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { FileOutlined } from '@ant-design/icons';
import { Link, Outlet, useParams } from "react-router-dom";

const apiUrl = "/session/rest/session";
const headerTitle = "Sessions";
const editPermission = "sessions:edit";
const deletePermission = "sessions:delete";
const addPermission = "sessions:add";

const columns = [
    {
        title: 'User',
        dataIndex: 'user_name',
        key: 'user_name',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Plan',
        dataIndex: 'plan_data',
        key: 'plan_data',
        render: (plan_data) => <div>{plan_data?.service_data?.title} - {plan_data?.name}</div>,
    },
    {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'notes',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Session Closed',
        dataIndex: 'session_closed',
        key: 'session_closed',
        render: (text, row, index) => {
            return row.session_closed ? "Yes" : "No"
        },
    },
    {
        title: 'Payment Status',
        dataIndex: 'payment_status',
        key: 'payment_status',
        render: (text, row, index) => {
            return row.payment_status ? "PAID" : "PENDING"
        },
    },
    {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created',
        render: (text, row, index) => {
            return <RenderCreated text={text} row={row} index={index} />
        },
    },
    // {
    //     title: 'Updated',
    //     dataIndex: 'updated_at',
    //     key: 'updated',
    //     render: (text, row, index) => {
    //         return <RenderUpdated text={text} row={row} index={index} />
    //     },
    // },
    {
        title: 'Review Documents',
        dataIndex: 'review_document',
        key: 'review_document',
        render: (text, row, index) => {
            return (
                <Link to={`${row.id}`}>
                    <Button
                        type="default"
                        shape="default"
                        icon={< FileOutlined size={18} />}
                        size="small"
                        style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 5 }}
                    >
                        Documents
                    </Button>
                </Link>
            )
        },
    },
    ...getCommonColumns({ apiUrl, deletePermission, editPermission, created: false, updated: false, action: true })
]

const SessionPage = () => {

    const { sessionId } = useParams();
    const userPermissions = useSelector(store => store.auth?.user?.permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    if (sessionId) {
        return <Outlet />
    }

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={SessionAddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default SessionPage;