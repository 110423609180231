import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import DocumentAddEditForm from "Components/Forms/Document/DocumentAddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";

const apiUrl = "/document/rest/documents";
const headerTitle = "Documents";
const editPermission = "documents:edit";
const deletePermission = "documents:delete";
const addPermission = "documents:add";

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'File',
        dataIndex: 'document_file',
        key: 'document_file',
        render: (text) => <a href={text} target="_blank">{text}</a>,
    },
    {
        title: 'Document Type',
        dataIndex: 'document_type_title',
        key: 'document_type_title',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        // render: (text) => <a>{text}</a>,
    },
    ...getCommonColumns({ apiUrl, deletePermission, editPermission, created: true, updated: true, action: true })
]

const BlogsPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={DocumentAddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default BlogsPage;