import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import SubscriptionAddEditForm from "Components/Forms/Subscription/SubscriptionAddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";

const apiUrl = "/marketing/rest/subscribe";
const headerTitle = "Subscriptions";
const editPermission = "subscriptions:edit";
const deletePermission = "subscriptions:delete";
const addPermission = "subscriptions:add";

const columns = [
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Subscribed?',
        dataIndex: 'subscribed',
        key: 'subscribed',
        render: (subscribed) => (subscribed ? 'Yes' : 'No'),
    },
    ...getCommonColumns({ apiUrl, deletePermission, editPermission, created: true, updated: true, action: true })
]

const SubscriptionsPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={SubscriptionAddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default SubscriptionsPage;