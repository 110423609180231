import React, { useEffect, useState } from 'react';
import { Button, Form, notification, DatePicker, Select, Checkbox, Flex, Space } from 'antd';
import type { CheckboxProps, DatePickerProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord, fetchAllUsers, fetchAllPlans } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons"
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';

const SessionAddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [plans, setPlans] = useState([]);

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);
  const [sessionClosed, setSessionClosed] = useState(false);
  const [paymentPaid, setPaymentPaid] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState('');

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
    return form.resetFields();
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    form.setFieldsValue(currentRecordData);
    form.setFieldValue("delivery_date", moment(currentRecordData.delivery_date));
    setSessionClosed(currentRecordData.session_closed);
    setPaymentPaid(currentRecordData.payment_status);
    setDeliveryDate(currentRecordData.delivery_date);
  }, [fetchRecordStatus, currentRecordData]);


  const fetchUsers = async () => {
    const response = await fetchAllUsers();
    if (response) {
      setUsers(response.results)
    }
  }

  const fetchPlans = async () => {
    const response = await fetchAllPlans();
    if (response) {
      setPlans(response.results)
    }
  }

  useEffect(() => {
    fetchUsers();
    fetchPlans();
  }, [])

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const addNew = (val) => {
    // console.log(val);
    dispatch(addARecord({ apiUrl: apiUrl, data: val }));
  }

  const edit = (val) => {
    // console.log(val);
    dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val }));
  }

  const onSessionChange: CheckboxProps['onChange'] = (e) => {
    setSessionClosed(e.target.checked);
  };

  const onPaymentChange: CheckboxProps['onChange'] = (e) => {
    setPaymentPaid(e.target.checked);
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    console.log(date, dateString);
    setDeliveryDate(dateString)
  };

  const onFinish = (val) => {
    console.log(val);
    if (currentFormMode === FormModesEnum.ADD) {
      addNew({ ...val, "session_closed": sessionClosed, "payment_status": paymentPaid, "delivery_date": deliveryDate });
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit({ ...val, "session_closed": sessionClosed, "payment_status": paymentPaid, "delivery_date": deliveryDate });
    } else {
      console.log("No suitable mode found");
    }
  }

  return (
    <>
      {contextHolder}
      <Form layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item name="user" label="User" rules={[{ required: true }]}>
          <Select
            placeholder="Select a User"
            allowClear
          >
            {users.map((user) => {
              return <option key={user.id} value={user.id}>{user.first_name} {user.last_name} - {user.username}</option>
            })}
          </Select>
        </Form.Item>
        <Form.Item name="plan" label="Plan" rules={[{ required: true }]}>
          <Select
            placeholder="Select a Plan"
            allowClear
          >
            {plans.map((plan) => {
              return <option key={plan.id} value={plan.id}>{plan.name} - {plan.service_data.title}</option>
            })}
          </Select>
        </Form.Item>
        <Form.Item name="notes" label="Notes">
          <TextArea rows={4} placeholder="Enter Notes" />
        </Form.Item>
        <Form.Item>
          <Flex justify="space-between">
            <Form.Item name="session_closed" noStyle>
              <Checkbox checked={sessionClosed} onChange={onSessionChange}>Close Session</Checkbox>
            </Form.Item>
          </Flex>
        </Form.Item>
        <Form.Item>
          <Flex justify="space-between">
            <Form.Item name="payment_status" noStyle>
              <Checkbox checked={paymentPaid} onChange={onPaymentChange}>Paid</Checkbox>
            </Form.Item>
          </Flex>
        </Form.Item>
        <Form.Item>
          <Flex justify="space-between">
            <Form.Item name="delivery_date" noStyle>
              <DatePicker onChange={onChange} />
            </Form.Item>
          </Flex>
        </Form.Item>
        {
          currentFormMode === FormModesEnum.ADD ? <Form.Item>
            {
              addRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Submitting
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
            }
          </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
            {
              editRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Updating
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Update
                </Button>
            }
          </Form.Item> : <></>
        }
      </Form >
    </>
  );
};

export default SessionAddEditForm;