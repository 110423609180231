import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as actions from "Redux/MasterReducer/crudSlices";
import { Button, Flex, Select, Row, Col, Form } from "antd";
import { EyeOutlined } from '@ant-design/icons';

const SessionDocumentPage = () => {

    const { sessionId } = useParams();
    const [currentSession, setCurrentSession] = useState(null);
    const [docTypes, setDocTypes] = useState([]);
    const [customDocTypes, setCustomDocTypes] = useState([]);
    const [reqDocuments, setReqDocuments] = useState([]);
    const [currentDocuments, setCurrentDocuments] = useState([]);
    const [data, setData] = useState([]);
    const [uploadData, setUploadData] = useState(null);

    let allOptions = [
        { value: "PENDING", label: "PENDING" },
        { value: "APPROVED", label: "APPROVED" },
        { value: "REJECTED", label: "REJECTED" },
    ]

    let allDocOptions = docTypes.map((doc) => {
        return {
            value: doc.id,
            label: doc.title,
        }
    });

    let docOptions = customDocTypes.map((doc) => {
        return {
            value: doc.document_type,
            label: doc.document_type_title,
        }
    });

    const handleDocChange = async (selectedValues) => {
        setUploadData(selectedValues);
    }

    const fetchSession = async () => {
        const response = await actions.getSession(sessionId);
        if (response) {
            setCurrentSession(response);
            setReqDocuments([...response.required_document_types, ...response.custom_required_document_types]);
            setCurrentDocuments(response.session_documents)
        }
    };

    const fetchAllDocumentTypes = async () => {
        const response = await actions.getDocumentTypes();
        if (response) {
            setDocTypes(response.results)
        }
    }

    const fetchCustomSessionDocumentTypes = async () => {
        const response = await actions.getCustomSessionDocumentTypes(sessionId);
        if (response) {
            setCustomDocTypes(response.results)
        }
    }

    const handleChange = async (selectedStatus, sessionDocumentId) => {
        setData([...data, { "id": sessionDocumentId, "status": selectedStatus }]);
    }

    const submitData = async () => {
        const response = await actions.updateSessionDocuments(data);
        if (response) {
            console.log(response);
        }
    }

    const submitCustomDocTypes = async () => {
        const response = await actions.createCustomSessionDocumentTypes({ "session": sessionId, "selected_values": uploadData });
        if (response) {
            fetchSession();
        }
    }

    useEffect(() => {
        fetchAllDocumentTypes();
        if (sessionId) {
            fetchSession();
            fetchCustomSessionDocumentTypes();
        }
    }, [sessionId])

    return (
        <div>
            <h3>Request More documents:</h3>
            <Flex style={{ marginBottom: 40 }}>
                {docOptions.length > 0 && <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '50%', marginBottom: '10px' }}
                    placeholder="Please select"
                    defaultValue={docOptions}
                    onChange={handleDocChange}
                    options={allDocOptions}
                />}
                {docOptions.length === 0 && <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '50%', marginBottom: '10px' }}
                    placeholder="Please select"
                    onChange={handleDocChange}
                    options={allDocOptions}
                />}
                <Button onClick={submitCustomDocTypes} style={{ width: 100 }}>Submit</Button>
            </Flex>
            <h3>Documents</h3>
            <Form
                className={`w-100 p-4 d-flex flex-column justify-content-center align-items-center`}
                style={{ borderRadius: 24 }}
            // onSubmit={handleSubmit}
            >
                <Flex
                    vertical
                    justify="center"
                    style={{ borderRadius: 24 }}
                >
                    <Row className="py-2" style={{ fontWeight: 600 }}>
                        <Col span={2}>Sr. No.</Col>
                        <Col span={5} className="d-flex align-items-center">
                            <span className="body-text2 text-secondary">Document Type</span>
                        </Col>
                        <Col span={5} className="d-flex align-items-center">
                            <span className="body-text2 text-secondary">Document Name</span>
                        </Col>
                        <Col
                            span={4}
                            className="d-flex align-items-center body-text2 text-secondary"
                        >
                            Approval Status
                        </Col>
                        <Col
                            span={4}
                            className="d-flex align-items-center body-text2 text-secondary"
                        >
                            Size
                        </Col>
                        <Col span={4}>View Document</Col>
                    </Row>
                    {reqDocuments.map((document, index) => {
                        let is_available = currentDocuments.filter(
                            (doc) => doc.document_type === document.document_type
                        );
                        return (
                            <>
                                <Row style={{ marginTop: 20 }}>
                                    <Col span={2}>
                                        {index + 1}
                                    </Col>
                                    <Col span={5}>
                                        {document.document_type_title}
                                    </Col>
                                    <Col span={5}>
                                        {is_available[0]?.document_data?.name || "-"}
                                    </Col>
                                    <Col span={4}>
                                        {is_available[0]?.status ?
                                            <Select
                                                style={{ width: '50%', marginBottom: '10px' }}
                                                placeholder="Please select"
                                                defaultValue={is_available[0]?.status}
                                                onChange={(e) => handleChange(e, is_available[0]?.id)}
                                                options={allOptions}
                                            />
                                            :
                                            "UNAVAILABLE"
                                        }
                                    </Col>
                                    <Col span={4}>
                                        {is_available[0]?.document_data?.size || "-"}
                                    </Col>
                                    <Col span={4} >
                                        {is_available.length > 0 ?
                                            <div >
                                                <a
                                                    href={is_available[0]?.document_data?.document_file}
                                                    target="_blank"
                                                >
                                                    <EyeOutlined style={{ fontSize: 20 }} />
                                                </a>
                                            </div>
                                            :
                                            <div>
                                                <EyeOutlined style={{ fontSize: 20 }} />
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </>
                        );
                    })}
                </Flex>
                <Flex vertical style={{ marginTop: 20 }}>
                    <Button onClick={submitData} style={{ width: 100 }}>Submit</Button>
                </Flex>
            </Form>
        </div>
    )
}

export default SessionDocumentPage;