import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import BlogAddEditForm from "Components/Forms/Blog/BlogAddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { Image } from "antd";
import { useSelector } from "react-redux";

const apiUrl = "/blog/rest/blogs";
const headerTitle = "Blogs";
const editPermission = "blogs:edit";
const deletePermission = "blogs:delete";
const addPermission = "blogs:add";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Slug',
        dataIndex: 'slug',
        key: 'slug',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Blog Image',
        dataIndex: 'blog_image',
        key: 'blog_image',
        render: (text) => <Image src={text} />,
    },
    {
        title: 'Read Time',
        dataIndex: 'read_time',
        key: 'read_time',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Author Name',
        dataIndex: 'author_name',
        key: 'author_name',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Published Date',
        dataIndex: 'published_date',
        key: 'published_date',
        // render: (text) => <a>{text}</a>,
    },
    ...getCommonColumns({ apiUrl, deletePermission, editPermission, created: true, updated: true, action: true })
]

const BlogsPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={BlogAddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default BlogsPage;