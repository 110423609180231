import React, { useEffect, useState } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { IoSunnyOutline } from "react-icons/io5";
import { IoMdMoon, IoMdNotifications } from "react-icons/io";
import { Layout, Button, theme, Flex, Space, Tooltip, Popover, List, Typography, Badge } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { switchTheme } from 'Redux/UtilitiesReducer/utilitySlice';
import { ThemeEnum } from 'Redux/enums';
import { IoMdLogOut } from 'react-icons/io';
import Title from 'antd/es/typography/Title';
import dispatchLogoutAction from 'Redux/logoutAction';
import * as actions from "Redux/MasterReducer/crudSlices";
import { useNavigate } from 'react-router';
const { Header } = Layout;
const { Text } = Typography;

const DARK_COLOR = "#001529";
const LIGHT_COLOR = "white";

const TopNavigation = ({ collapsed, setCollapsed }) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const currentTheme = useSelector(store => store.utilities.theme);
    const currentHeaderTitle = useSelector(store => store.utilities.currentHeaderTitle);
    const currentUserFirstName = useSelector(store => store.auth?.user?.first_name);
    const currentUserLastName = useSelector(store => store.auth?.user?.last_name);
    const userId = useSelector(store => store.auth?.user?.id);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);

    const fetchNotifications = async () => {
        const response = await actions.fetchUnArchivedNotifications(userId)
        setNotifications(response)
        setUnreadCount(response.length)
    };

    useEffect(() => {
        fetchNotifications();
        const interval = setInterval(fetchNotifications, 30000);
        return () => clearInterval(interval);
    }, [userId]);

    const logoutUser = () => {
        dispatchLogoutAction();
    };

    const markAsRead = (id, link) => {
        setNotifications((prevNotifications) =>
            prevNotifications.map((notification) =>
                notification.id === id ? { ...notification, is_read: true } : notification
            )
        );

        if (link) {
            navigate(link);  // Navigate if link exists
        }
    };

    const archiveNotification = (id) => {
        setNotifications((prevNotifications) =>
            prevNotifications.filter((notification) => notification.id !== id)
        );
    };

    const notificationContent = (
        <div style={{ width: 400, height: 500, overflowY: 'auto' }}>
            <List
                itemLayout="horizontal"
                dataSource={notifications}
                renderItem={(item) => (
                    <List.Item
                        onClick={() => markAsRead(item.id, item.link)}
                        style={{
                            backgroundColor: item.is_read ? "lightgrey" : "white",
                            padding: "10px",
                            cursor: item.link ? "pointer" : "default",  // Show pointer cursor if link exists
                            position: "relative"
                        }}
                    >
                        <List.Item.Meta
                            title={<Text delete={item.is_read}>{item.title}</Text>}
                            description={item.description}
                        />
                        <Button
                            type="text"
                            onClick={(e) => {
                                e.stopPropagation();
                                archiveNotification(item.id);
                            }}
                            style={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                                color: currentTheme === ThemeEnum.DARK ? LIGHT_COLOR : DARK_COLOR,
                            }}
                        >
                            X
                        </Button>
                    </List.Item>
                )}
            />
        </div>
    );

    return (
        <>
            <Header
                style={{
                    padding: 0,
                    backgroundColor: currentTheme === ThemeEnum.DARK ? DARK_COLOR : LIGHT_COLOR,
                }}
            >
                <Flex justify='space-between' align='center'>
                    <Space direction='horizontal' style={{ alignItems: "center", justifyContent: "center" }}>
                        <Button
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                                color: currentTheme === ThemeEnum.DARK ? LIGHT_COLOR : DARK_COLOR,
                            }}
                        />
                        <Title
                            style={{
                                margin: 0,
                                color: currentTheme === ThemeEnum.DARK ? LIGHT_COLOR : DARK_COLOR,
                            }} level={4}
                        >{currentHeaderTitle}</Title>
                    </Space>
                    <Space direction='horizontal' style={{ alignItems: "center", justifyContent: "center" }} className='top-nav-buttons'>
                        <Button
                            type="text"
                            icon={currentTheme === ThemeEnum.LIGHT ? <IoMdMoon size={24} /> : <IoSunnyOutline size={24} />}
                            onClick={() => { dispatch(switchTheme()); }}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                                color: currentTheme === ThemeEnum.DARK ? LIGHT_COLOR : DARK_COLOR,
                            }}
                        />
                        <Popover
                            content={notificationContent}
                            title="Notifications"
                            trigger="click"
                            placement="bottomRight"
                        >
                            <Badge count={unreadCount} offset={[-10, 20]}>
                                <Button
                                    type="text"
                                    icon={<IoMdNotifications size={24} />}
                                    style={{
                                        fontSize: '16px',
                                        width: 64,
                                        height: 64,
                                        color: currentTheme === ThemeEnum.DARK ? LIGHT_COLOR : DARK_COLOR,
                                    }}
                                />
                            </Badge>
                        </Popover>
                        <Tooltip placement="bottom" title={"Logout"}>
                            <Button
                                type="text"
                                icon={<Space style={{ padding: "0px 10px" }} direction='horizontal'><IoMdLogOut size={24} />{`${currentUserFirstName} ${currentUserLastName}`}</Space>}
                                onClick={logoutUser}
                                style={{
                                    fontSize: '16px',
                                    width: "fit-content",
                                    height: 64,
                                    color: currentTheme === ThemeEnum.DARK ? LIGHT_COLOR : DARK_COLOR,
                                }}
                            />
                        </Tooltip>
                    </Space>
                </Flex>
            </Header>
        </>
    );
};

export default TopNavigation;
