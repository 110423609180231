import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";


const AccessControl = (props) => {

    const { children, userPermissions = [], allowedpermissions = [], renderNoAccess } = props;

    const [loading, setLoading] = useState(true);
    const [permitted, setPermitted] = useState(false);

    useEffect(() => {
        if (allowedpermissions.length === 0) {
            setPermitted(true);
        } else {
            for (let i in allowedpermissions) {
                if (userPermissions?.find(item => item === allowedpermissions[i])) {
                    setPermitted(true);
                    break;
                }
            }
        }
        setLoading(false);
    }, [userPermissions]);

    if (!permitted) {
        return renderNoAccess();
    }

    return (
        <>
            {children}
        </>
    );
}

export default AccessControl;
