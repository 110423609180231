import React, { useEffect } from 'react';
import { Button, Form, Input, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import TextArea from 'antd/es/input/TextArea';
import FormElements from './FormElements';

const ContactUsAddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
    return form.resetFields();
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    form.setFieldsValue(currentRecordData);
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    } else {
      form.resetFields();
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const addNew = (val) => {
    dispatch(addARecord({ apiUrl: apiUrl, data: val, headers: { "Content-Type": "multipart/form-data" } }));
  }

  const edit = (val) => {
    dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val, headers: { "Content-Type": "multipart/form-data" } }));
  }

  const onFinish = (val) => {
    const formData = new FormData();
    Object.keys(val).forEach((key) => {
      formData.append(key, val[key]);
    });
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(formData);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(formData);
    } else {
      console.log("No suitable mode found");
    }
  }

  return (
    <>
      {contextHolder}
      <Form layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        {/* <Form.Item name="full_name" label="Full Name"
          rules={[
            {
              required: true,
              message: 'Please enter Full Name',
            },
          ]}
        >
          <Input placeholder="Enter Full Name" />
        </Form.Item>
        <Form.Item name="email" label="Email"
          rules={[
            {
              required: true,
              message: 'Please enter the Email',
            },
          ]}
        >
          <Input placeholder="Enter Email" />
        </Form.Item>
        <Form.Item name="phone" label="Phone Number"
          rules={[
            {
              required: true,
              message: 'Please enter the Phone Number',
            },
          ]}
        >
          <Input placeholder="Enter Phone Number" />
        </Form.Item>
        <Form.Item name="message" label="Message">
          <TextArea rows={4} placeholder="Enter Message" />
        </Form.Item> */}
        <FormElements />
        {
          currentFormMode === FormModesEnum.ADD ? <Form.Item>
            {
              addRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Submitting
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
            }
          </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
            {
              editRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Updating
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Update
                </Button>
            }
          </Form.Item> : <></>
        }

      </Form >
    </>
  );
};
export default ContactUsAddEditForm;