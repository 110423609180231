import { RenderCreated, getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import ContactUsAddEditForm from "Components/Forms/ContactUs/ContactUsAddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { FileOutlined } from '@ant-design/icons';
import { Link, Outlet, useParams } from "react-router-dom";

const apiUrl = "/marketing/rest/contactus";
const headerTitle = "Contact Us";
const editPermission = "contactus:edit";
const deletePermission = "contactus:delete";
const addPermission = "contactus:add";

const columns = [
    {
        title: 'Full Name',
        dataIndex: 'full_name',
        key: 'full_name',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: 'Contacted?',
        dataIndex: 'contacted',
        key: 'contacted',
        render: (contacted) => (contacted ? 'Yes' : 'No'),
    },
    {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created',
        render: (text, row, index) => {
            return <RenderCreated text={text} row={row} index={index} />
        },
    },
    {
        title: 'Services',
        dataIndex: 'services',
        key: 'services',
        render: (text, row, index) => {
            return (
                <Link to={`${row.id}`}>
                    <Button
                        type="default"
                        shape="default"
                        icon={<FileOutlined size={18} />}
                        size="small"
                        style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 5 }}
                    >
                        Services
                    </Button>
                </Link>
            )
        },
    },
    ...getCommonColumns({ apiUrl, deletePermission, editPermission, created: false, updated: false, action: false })
]

const ContactUsPage = () => {

    const { contactUsId } = useParams();
    const userPermissions = useSelector(store => store.auth?.user?.permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    if (contactUsId) {
        return <Outlet />
    }

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={ContactUsAddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default ContactUsPage;