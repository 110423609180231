import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import ReviewAddEditForm from "Components/Forms/Review/ReviewAddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { Image } from "antd";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

const apiUrl = "/marketing/rest/review";
const headerTitle = "Reviews";
const editPermission = "reviews:edit";
const deletePermission = "reviews:delete";
const addPermission = "reviews:add";

const columns = [
    {
        title: 'Name',
        dataIndex: 'full_name',
        key: 'full_name',
    },
    {
        title: 'Profile Image',
        dataIndex: 'profile_image',
        key: 'profile_image',
        render: (text) => <Image src={text} />,
    },
    {
        title: 'Visible',
        dataIndex: 'show',
        key: 'show',
        render: (isVisible) => (isVisible ? 'Yes' : 'No'),
    },
    {
        title: 'Review Type',
        dataIndex: 'review_type',
        key: 'review_type',
    },
    {
        title: 'Rating',
        dataIndex: 'rating',
        key: 'rating',
    },
    {
        title: 'Date of Review',
        dataIndex: 'date',
        key: 'date',
        render: (text) => moment(text).format("DD MMM yyyy, hh:mm a"),
    },
    ...getCommonColumns({ apiUrl, deletePermission, editPermission, created: false, updated: true, action: true })
]

const ReviewsPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={ReviewAddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default ReviewsPage;