import { Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';

const FormElements = ({ showAll = false }) => {

    return (
        <>
            <Form.Item name="full_name" label="Full Name"
                rules={[
                    {
                        required: true,
                        message: 'Please enter Full Name',
                    },
                ]}
            >
                <Input placeholder="Enter Full Name" />
            </Form.Item>
            <Form.Item name="email" label="Email"
                rules={[
                    {
                        required: true,
                        message: 'Please enter the Email',
                    },
                ]}
            >
                <Input placeholder="Enter Email" />
            </Form.Item>
            <Form.Item name="phone" label="Phone Number"
                rules={[
                    {
                        required: true,
                        message: 'Please enter the Phone Number',
                    },
                ]}
            >
                <Input placeholder="Enter Phone Number" />
            </Form.Item>
            <Form.Item name="message" label="Message">
                <TextArea rows={4} placeholder="Enter Message" />
            </Form.Item>
        </>
    );
};

export default FormElements;