import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Switch, notification, Upload, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons"
import TextArea from 'antd/es/input/TextArea';
import { CKEditor } from "ckeditor4-react";
import axios from "axios";
import moment from 'moment-timezone';

const BlogAddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [blogImageUrl, setBlogImageUrl] = useState('');
  const [authorImageUrl, setAuthorImageUrl] = useState('');

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);

  const [content, setContent] = useState("");

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
    return form.resetFields();
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    form.setFieldsValue(currentRecordData);
    form.setFieldValue("published_date", moment(currentRecordData.published_date));
    setContent(currentRecordData.content);
    setBlogImageUrl(currentRecordData.blog_image);
    setAuthorImageUrl(currentRecordData.author_image);
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    } else {
      // clear an edit form after closing it
      form.resetFields();
      setContent("Enter content here!!")
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const addNew = (val) => {
    dispatch(addARecord({ apiUrl: apiUrl, data: val, headers: { "Content-Type": "multipart/form-data" } }));
  }

  const edit = (val) => {
    dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val, headers: { "Content-Type": "multipart/form-data" } }));
  }

  const onFinish = (val) => {
    if (val['blog_image']['fileList']) {
      let currentAuthorImage = val['blog_image']['fileList'][0]['originFileObj']
      val['blog_image'] = currentAuthorImage;
    }
    if (val['author_image']['fileList']) {
      let currentAuthorImage = val['author_image']['fileList'][0]['originFileObj']
      val['author_image'] = currentAuthorImage;
    }
    let formattedDate = moment(val['published_date']).format('YYYY-MM-DD');
    val['published_date'] = formattedDate;
    val['content'] = content;
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
    } else {
      console.log("No suitable mode found");
    }
  }

  const blogImageBeforeUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBlogImageUrl(reader.result);
    };
    return false;
  };

  const authorImageBeforeUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      console.log(reader.result)
      setAuthorImageUrl(reader.result);
    };
    return false;
  };

  const editorConfig = {
    extraPlugins: ["tableresize", "uploadimage"],
    skin: "moono-lisa",
    removePlugins: [
      "exportpdf",
      "forms",
      "smiley",
      "language",
      "iframe",
      "about",
    ],
    filebrowserImageUploadUrl:
      process.env.REACT_APP_BASE_URL + `blog/rest/blogs/upload_blog_image/`,
    fileTools_requestHeaders: axios.defaults.headers.common,
    uploadUrl: process.env.REACT_APP_BASE_URL + `blog/rest/blogs/upload_blog_image/`,
    contentsCss: [
      process.env.REACT_APP_BASE_URL +
      "/staticfiles/ckeditor/ckeditor/contents.css",
    ],
    bodyClass: "document-editor",
    height: "8cm",
    width: "100%",
    allowedContent: true,
    exportPdf_options: {
      header_html: '<div class="styled">Header content</div>',
      footer_html: '<div class="styled"><span class="pageNumber"></span></div>',
      header_and_footer_css:
        ".styled { font-weight: bold; padding: 10px; text-align: center; color: red; }",
      margin_left: "1cm",
      margin_right: "2cm",
      format: "A5",
      page_orientation: "landscape",
    },
    protectedSource: [/{%[\s\S]*?%}+/g, /{{[\s\S]*?}}+/g],
  };

  return (
    <>
      {contextHolder}
      <Form layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item name="title" label="Title"
          rules={[
            {
              required: true,
              message: 'Please enter title',
            },
          ]}
        >
          <Input placeholder="Enter title" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea rows={4} placeholder="Enter Description" />
        </Form.Item>
        <Form.Item name="meta_title" label="Meta Title">
          <Input placeholder="Enter Meta Title" />
        </Form.Item>
        <Form.Item name="meta_description" label="Meta Description">
          <TextArea rows={4} placeholder="Enter Meta Description" />
        </Form.Item>
        <Form.Item name="slug" label="Slug">
          <Input placeholder="Enter the slug" />
        </Form.Item>
        {(content || currentFormMode === FormModesEnum.ADD) &&
          <Form.Item name="content" label="Content">
            <CKEditor
              name="content"
              initData={content}
              config={editorConfig}
              editorUrl={
                process.env.REACT_APP_BASE_URL +
                "/staticfiles/ckeditor/ckeditor/ckeditor.js"
              }
              onChange={(event) => {
                const data = event.editor.getData();
                console.log(data)
                setContent(data);
              }}
            />
          </Form.Item>
        }
        <Form.Item name="is_trending" label="Is Trending">
          <Switch />
        </Form.Item>
        <Form.Item name="blog_image" label="Blog Image">
          <Upload
            beforeUpload={blogImageBeforeUpload}
            showUploadList={false}
          >
            {blogImageUrl ? (
              <img src={blogImageUrl} alt="blog" style={{ maxWidth: '100%', maxHeight: '200px' }} />
            ) : (
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            )}
          </Upload>
        </Form.Item>
        <Form.Item name="read_time" label="Read Time">
          <Input placeholder="Enter total read time" />
        </Form.Item>
        <Form.Item name="author_name" label="Author Name">
          <Input placeholder="Enter Author Name" />
        </Form.Item>
        <Form.Item name="author_image" label="Author Image">
          <Upload
            beforeUpload={authorImageBeforeUpload}
            showUploadList={false}
          >
            {authorImageUrl ? (
              <img src={authorImageUrl} alt="blog" style={{ maxWidth: '100%', maxHeight: '200px' }} />
            ) : (
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            )}
          </Upload>
        </Form.Item>
        <Form.Item name="tags" label="Tags" help="Please separate tags with commas, no spaces">
          <Input placeholder="Enter tags" />
        </Form.Item>
        <Form.Item name="published_date" label="Published Date">
          <DatePicker />
        </Form.Item>

        {
          currentFormMode === FormModesEnum.ADD ? <Form.Item>
            {
              addRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Submitting
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
            }
          </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
            {
              editRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Updating
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Update
                </Button>
            }
          </Form.Item> : <></>
        }

      </Form >
    </>
  );
};
export default BlogAddEditForm;