import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Switch, notification, Upload, Select, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons"
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment-timezone';

const ReviewAddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [profileImageUrl, setProfileImageUrl] = useState('');

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);

  let allOptions = [
    { value: "GOOGLE", label: "GOOGLE" },
    { value: "TRUSTPILOT", label: "TRUSTPILOT" },
    { value: "PERSONAL", label: "PERSONAL" },
  ]

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
    return form.resetFields();
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    form.setFieldsValue(currentRecordData);
    form.setFieldValue("date", moment(currentRecordData.date));
    setProfileImageUrl(currentRecordData.profile_image);
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    } else {
      form.resetFields();
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const addNew = (val) => {
    dispatch(addARecord({ apiUrl: apiUrl, data: val, headers: { "Content-Type": "multipart/form-data" } }));
  }

  const edit = (val) => {
    dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val, headers: { "Content-Type": "multipart/form-data" } }));
  }

  const onFinish = (val) => {
    console.log(val);
    if (typeof val['profile_image'] !== 'string' && val['profile_image']) {
      let currentProfileImage = val['profile_image']['fileList'][0]['originFileObj']
      val['profile_image'] = currentProfileImage;
    }
    let formattedDate = moment(val['date']).format('YYYY-MM-DD');
    val['date'] = formattedDate;
    const formData = new FormData();
    Object.keys(val).forEach((key) => {
      formData.append(key, val[key]);
    });
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(formData);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(formData);
    } else {
      console.log("No suitable mode found");
    }
  }

  const profileImageBeforeUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setProfileImageUrl(reader.result);
    };
    return false;
  };

  return (
    <>
      {contextHolder}
      <Form layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item name="full_name" label="Name"
          rules={[
            {
              required: true,
              message: 'Please enter the Name',
            },
          ]}
        >
          <Input placeholder="Enter Name" />
        </Form.Item>
        <Form.Item name="review" label="Review"
          rules={[
            {
              required: true,
              message: 'Please enter the Review',
            },
          ]}
        >
          <TextArea rows={4} placeholder="Enter Review" />
        </Form.Item>
        <Form.Item name="rating" label="Rating"
          rules={[
            {
              required: true,
              message: 'Please enter the Rating',
            },
          ]}
        >
          <Input type='number' placeholder="5" min={1} max={5} />
        </Form.Item>
        <Form.Item name="review_type" label="Review Type"
          rules={[
            {
              required: true,
              message: 'Please select Review Type',
            },
          ]}
        >
          <Select
            style={{ width: '100%' }}
            placeholder="Please select"
            // defaultValue={is_available[0]?.status}
            // onChange={(e) => handleChange(e, is_available[0]?.id)}
            options={allOptions}
          />
        </Form.Item>
        <Form.Item name="date" label="Date of Review"
          rules={[
            {
              required: true,
              message: 'Please enter the date of review',
            },
          ]}
        >
          <DatePicker onChange={(date) => form.setFieldValue('date', date)} />
        </Form.Item>
        <Form.Item name="show" label="Show on website?">
          <Switch />
        </Form.Item>
        <Form.Item name="profile_image" label="Profile Image">
          <Upload
            beforeUpload={profileImageBeforeUpload}
            showUploadList={false}
          >
            {profileImageUrl ? (
              <img src={profileImageUrl} alt="blog" style={{ maxWidth: '100%', maxHeight: '200px' }} />
            ) : (
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            )}
          </Upload>
        </Form.Item>
        {
          currentFormMode === FormModesEnum.ADD ? <Form.Item>
            {
              addRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Submitting
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
            }
          </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
            {
              editRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Updating
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Update
                </Button>
            }
          </Form.Item> : <></>
        }

      </Form >
    </>
  );
};
export default ReviewAddEditForm;